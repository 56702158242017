import React from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/top/Home";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import FirstQuestion from "./pages/user/FirstQuestion";
import SecondQuestion from "./pages/user/SecondQuestion";
import ThirdQuestion from "./pages/user/ThirdQuestion";
import Result from "./pages/user/Result";
import Admin from "./pages/admin/Admin";
import SendAnswer from "./pages/user/SendAnswer";
import Logout from "./pages/user/Logout";
import Login from "./pages/top/Login";
import LoginHome from "./pages/top/LoginHome";
import UserRegistration from "./pages/top/UserRegistration";
import Slide2 from "./pages/top/Slide2";
import Slide3 from "./pages/top/Slide3";
import Slide4 from "./pages/top/Slide4";
import Slide5 from "./pages/top/Slide5";
import AdminAddUser from "./pages/admin/AdminAddUser";

import Amplify from "@aws-amplify/core";
import awsmobile from "./aws-exports";
import { I18n } from "aws-amplify";
import { vocabularies } from "./assets/i18n/amplify/vocabularies";
import CertificationAdmin from "./pages/admin/CertificationAdmin";

I18n.putVocabularies(vocabularies);
I18n.setLanguage("ja");

Amplify.configure(awsmobile);

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/home" component={Home} exact={true} />
        <Route path="/first" component={FirstQuestion} />
        <Route path="/second" component={SecondQuestion} />
        <Route path="/third" component={ThirdQuestion} />
        <Route path="/result" component={Result} />
        <Route path="/admin" component={Admin} />
        <Route path="/admin-add-user" component={AdminAddUser} />
        <Route
          path="/admin-certification-user"
          component={CertificationAdmin}
        />
        <Route path="/send-answer" component={SendAnswer} />
        <Route path="/logout" component={Logout} />
        <Route path="/login" component={Login} />
        <Route path="/login-home" component={LoginHome} />
        <Route path="/user-registration" component={UserRegistration} />
        <Route path="/slide2" component={Slide2} />
        <Route path="/slide3" component={Slide3} />
        <Route path="/slide4" component={Slide4} />
        <Route path="/slide5" component={Slide5} />
        <Redirect exact from="/" to="/login" />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);
export default App;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserData = /* GraphQL */ `
  query GetUserData($id: ID!) {
    getUserData(id: $id) {
      id
      userId
      userName
      loginDays
      email
      scoreA
      scoreB
      scoreC
      scoreD
      answerLimit
      answerValidation
      status
      sortFlg
      updatedAt
      createdAt
      owner
    }
  }
`;
export const listUserDatas = /* GraphQL */ `
  query ListUserDatas(
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        userName
        loginDays
        email
        scoreA
        scoreB
        scoreC
        scoreD
        answerLimit
        answerValidation
        status
        sortFlg
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const userNameByIndex = /* GraphQL */ `
  query UserNameByIndex(
    $userName: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNameByIndex(
      userName: $userName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userName
        loginDays
        email
        scoreA
        scoreB
        scoreC
        scoreD
        answerLimit
        answerValidation
        status
        sortFlg
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const updatedAtSort = /* GraphQL */ `
  query UpdatedAtSort(
    $sortFlg: Int
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    updatedAtSort(
      sortFlg: $sortFlg
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userName
        loginDays
        email
        scoreA
        scoreB
        scoreC
        scoreD
        answerLimit
        answerValidation
        status
        sortFlg
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const createdAtSort = /* GraphQL */ `
  query CreatedAtSort(
    $sortFlg: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    createdAtSort(
      sortFlg: $sortFlg
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userName
        loginDays
        email
        scoreA
        scoreB
        scoreC
        scoreD
        answerLimit
        answerValidation
        status
        sortFlg
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;

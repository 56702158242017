import React from "react";
import { IonContent, IonPage, IonButton, IonIcon, IonCard } from "@ionic/react";
import { arrowForward } from "ionicons/icons";
import "./Tutorial.css";
import { RouteComponentProps } from "react-router";
import Image from "../../assets/intro2.png";

interface OwnProps extends RouteComponentProps {}

const Slide2: React.FC = (history) => {
  const nextSlide = async () => {
    window.location.href = "/slide3";
  };

  return (
    <IonPage>
      <IonContent fullscreen class="home-content">
        <IonCard class="explanation-slide">
          <img src={Image} alt="イントロ画像" />
          <IonButton fill="clear" onClick={nextSlide}>
            2/5 次へ
            <IonIcon slot="end" icon={arrowForward} />
          </IonButton>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Slide2;

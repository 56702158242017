export const vocabularies = {
  ja: {
    'Sign In': 'ログイン',
    'Create account': '新規ユーザー作成',
    'No account?': 'アカウントが未登録ですか？',
    'Forgot your password?': 'パスワードをお忘れですか？',
    'Reset password': 'パスワードをリセット',
    'Enter your username': 'ユーザー名を入力',
    'Enter your password': 'パスワードを入力',
    'Username *': 'ユーザー名 * （必須、半角英語のみ） ※スペース無しで、8文字以上で登録可能',
    'Password *': 'パスワード * （必須、半角英語と数字が可） ※スペース無しで、8文字以上で登録可能',
    'Sign in to your account': 'アカウントにログイン',
    'Create a new account': '新しいアカウントを作成',
    'Email Address *': 'メールアドレス（必須、認証コード送信先）',
    'Phone Number *': '電話番号 (任意) 日本は+81です',
    'Username': 'ユーザー名',
    'Password': 'パスワード',
    'Email': 'メールアドレス',
    'Create Account': 'アカウント作成',
    'Have an account?': 'アカウントをお持ちですか？',
    'Sign in': 'ログインへ',
    'Reset your password': 'パスワードのリセット',
    'Send Code': 'パスワードをリセットします',
    'Back to Sign In': 'ログインへ戻る',
    'User does not exist.': 'ユーザー名とパスワードが一致しません',
    'Username/client id combination not found.': 'ユーザー名が存在しません',
    'Username cannot be empty': 'ユーザー名が入力されていません',
    'Password cannot be empty': 'パスワードが入力されていません',
    'Invalid email address format.': 'メールアドレスのフォーマットが一致しません',
    'Password did not conform with policy: Password not long enough': 'パスワードの規約が一致しません',
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": 'パスワードの文字列は6文字以上が必要です',
    'Attributes did not conform to the schema: email: The attribute is required': 'メールアドレスを入力してください',
    'Confirm Sign up': '認証キーの入力',
    'Confirmation Code': '認証コード',
    'Enter your code': '認証コードを入力してください',
    'Lost your code?': 'コードを紛失しましたか？',
    'Resend Code': 'コードを再送します',
    'Confirm': '確認',
    'Incorrect username or password.': 'パスワードが間違っています',
    'Cannot reset password for the user as there is no registered/verified email or phone_number': 'パスワードをリセットすることができません。メールアドレスや電話番号の認証が完了しているか確認してください',
    'Attributes did not conform to the schema: email: The attribute is required\n':'メールアドレスを入力してください',
    'Custom auth lambda trigger is not configured for the user pool.': 'カスタム認証は構成されていません',
  }
}
import {
  IonApp,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonCard,
  IonIcon,
  IonInput,
  IonItem,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import SideBar from "../../components/admin/SideBar";
import { eye, eyeOff } from "ionicons/icons";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { createUserData } from "../../graphql/mutations";
import { CreateUserDataMutation } from "../../API";

type Password = "password" | "text";

const AdminAddUser: React.FC = () => {
  const [username, setUserName] = useState("");
  const [adminUserName, setAdminUserName] = useState("");
  const [email, setEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState<Password>("password");
  const [passwordIcon, setPasswordIcon] = useState(eye);

  // userNameを取得するタイミングでauthuserを実行
  useEffect(() => {
    authuser();
    // eslint-disable-next-line
  }, [adminUserName]);

  const authuser = async () => {
    try {
      await Auth.currentUserInfo().then((res) => {
        if (res.username !== "createuser001") {
          window.location.href = "/";
        } else {
          setAdminUserName(res.username);
          console.log(res.username);
        }
      });
    } catch {
      window.location.href = "/";
    }
  };

  function togglePasswordMode() {
    if (passwordType === "password") {
      setPasswordType("text");
      setPasswordIcon(eyeOff);
    } else if (passwordType === "text") {
      setPasswordType("password");
      setPasswordIcon(eye);
    } else {
      setPasswordType("password");
      setPasswordIcon(eye);
    }
  }

  async function signUp() {
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email, // optional
          // optional - E.164 number convention
          // other custom attributes
        },
      });
      console.log(user);
      await makeUserData();
      await nextPage();
    } catch (error) {
      console.log("error signing up:", error);
    }
  }

  function nextPage() {
    window.location.href = "/admin-certification-user";
  }

  const makeUserData = async () => {
    const res = await API.graphql(
      graphqlOperation(createUserData, {
        input: {
          userName: username,
          email: userEmail,
          sortFlg: 1,
        },
      })
    );
    if ("data" in res && res.data) {
      const posts = res.data as CreateUserDataMutation;
      console.log(posts);
    }
  };

  return (
    <IonApp>
      <IonContent id="content" className="admin-background">
        {!adminUserName && <div>Loading...</div>}
        {adminUserName && (
          <IonGrid>
            <IonRow>
              <IonCol size="0.5">
                <SideBar />
              </IonCol>
              <IonCol size="11.5">
                <IonCard class="admin-create-user-card">
                  <div className="card-blank-space"></div>
                  <IonItem className="admin-input-item">
                    <IonInput
                      name="username"
                      type="text"
                      data-testid="input-username"
                      class="admin-input-value"
                      placeholder="ユーザーネーム(ID)"
                      onIonChange={(e: any) => setUserName(e.target.value)}
                    ></IonInput>
                  </IonItem>
                  <div className="card-blank-space-10px"></div>
                  <IonItem class="admin-input-item">
                    <IonInput
                      name="password"
                      type={passwordType}
                      class="admin-input-value"
                      placeholder="パスワード(PW)"
                      onIonChange={(e: any) => setPassword(e.target.value)}
                    ></IonInput>
                    <IonIcon
                      icon={passwordIcon}
                      item-right
                      onClick={togglePasswordMode}
                    ></IonIcon>
                  </IonItem>
                  <div className="card-blank-space-10px"></div>
                  <IonItem class="admin-input-item">
                    <IonInput
                      name="email"
                      type="email"
                      class="admin-input-value"
                      placeholder="メールアドレス"
                      onIonChange={(e: any) => setEmail(e.target.value)}
                    ></IonInput>
                  </IonItem>
                  <div className="card-blank-space-10px"></div>
                  <IonItem class="admin-input-item">
                    <IonInput
                      name="email"
                      type="email"
                      class="admin-input-value"
                      placeholder="利用者のメールアドレス"
                      onIonChange={(e: any) => setUserEmail(e.target.value)}
                    ></IonInput>
                  </IonItem>
                  <IonRow>
                    <IonCol>
                      <p data-testid="notes" className="instructions">
                        ※IDとPW 共に<br></br>半角英語のみ、スペースなし
                      </p>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <IonButton
                        data-testid="login-btn"
                        title="Add Todo"
                        className="fancy-button"
                        onClick={signUp}
                      >
                        ログイン
                      </IonButton>
                    </IonCol>
                  </IonRow>

                  <div className="card-blank-space"></div>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonApp>
  );
};

export default AdminAddUser;

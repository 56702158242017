import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./SendAnswer.css";
import Header from "../../components/top/Header";

import { API, Auth, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations";

// 算術ロジックimport
import { scoreCalculation } from "../../util/arithmetic";

// ログイン日数import
import { pattern } from "../../data/loginDays";

// localStorage
import { Plugins } from "@capacitor/core";
import { getUserDataAtHomePage } from "../../graphql/customQueries";
import { UserNameByIndexQuery } from "../../API";
const { Storage } = Plugins;

const SendAnswer: React.FC = () => {
  const [showAlert1, setShowAlert1] = useState(false);
  const [question1, setQuestion1] = useState();
  const [question2, setQuestion2] = useState();
  const [question3, setQuestion3] = useState();
  const [userId, setUserId] = useState<string>("");
  const [userScoreA, setUserScoreA] = useState<number>(0);
  const [userScoreB, setUserScoreB] = useState<number>(0);
  const [userScoreC, setUserScoreC] = useState<number>(0);
  const [userScoreD, setUserScoreD] = useState<number>(0);
  const [loginDays, setLoginDays] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [answerLimit, setAnswerLimit] = useState<number>(0);
  const [catchData, setCatchData] = useState<boolean>(false);

  useEffect(() => {
    authuser();
    // eslint-disable-next-line
  }, [userName]);

  const authuser = async () => {
    await Auth.currentUserInfo().then((res) => {
      setUserName(res.username);
    });
    await getUserData();
    await getAnswer();
    await setCatchData(true);
  };

  const getUserData = async () => {
    const result = await API.graphql(
      graphqlOperation(getUserDataAtHomePage, {
        userName: userName,
      })
    );
    if ("data" in result && result.data) {
      const posts = result.data as UserNameByIndexQuery;
      const id = posts.userNameByIndex?.items![0]?.id;
      const scoreA = posts.userNameByIndex?.items![0]?.scoreA;
      const scoreB = posts.userNameByIndex?.items![0]?.scoreB;
      const scoreC = posts.userNameByIndex?.items![0]?.scoreC;
      const scoreD = posts.userNameByIndex?.items![0]?.scoreD;
      const remainingAnswerLimit =
        posts.userNameByIndex?.items![0]?.answerLimit;
      const todayLoginDays = posts.userNameByIndex?.items![0]?.loginDays;
      setUserId(id!);
      setUserScoreA(scoreA!);
      setUserScoreB(scoreB!);
      setUserScoreC(scoreC!);
      setUserScoreD(scoreD!);
      setLoginDays(todayLoginDays!);
      setAnswerLimit(remainingAnswerLimit!);
    }
  };

  const getAnswer = async () => {
    const answer1 = await Storage.get({ key: "answer1" });
    const answer2 = await Storage.get({ key: "answer2" });
    const answer3 = await Storage.get({ key: "answer3" });
    const question1 = JSON.parse(answer1.value!);
    const question2 = JSON.parse(answer2.value!);
    const question3 = JSON.parse(answer3.value!);
    setQuestion1(question1.answer);
    setQuestion2(question2.answer);
    setQuestion3(question3.answer);
  };

  const sampleData = () => {
    const sample = scoreCalculation(question1!, question2!, question3!);
    let lastAnswerLimit;
    let answerValidation;
    if (answerLimit === 1) {
      answerValidation = false;
      lastAnswerLimit = 5;
      console.log(answerValidation);
    } else {
      answerValidation = true;
      lastAnswerLimit = +answerLimit - 1;
    }
    const loginPattern = pattern(
      +loginDays! + 1,
      sample!.score1,
      sample!.score2
    );
    const userScores = {
      id: userId,
      scoreA: +userScoreA! + loginPattern!.plusScoreA,
      scoreB: +userScoreB! + loginPattern!.plusScoreB,
      scoreC: +userScoreC! + loginPattern!.plusScoreC,
      scoreD: +userScoreD! + loginPattern!.plusScoreD,
      loginDays: +loginDays! + 1,
      answerLimit: lastAnswerLimit,
      answerValidation: answerValidation,
    };
    return userScores;
  };

  const updateScore = async () => {
    const userScores = sampleData();
    await API.graphql({
      query: mutations.updateUserData,
      variables: { input: userScores },
    });
  };

  return (
    <IonPage>
      {!catchData && <div>Loading...</div>}
      {catchData && (
        <>
          <Header />
          <IonContent class="home-content">
            <IonCard>
              <IonItem>
                <IonCardTitle>{+loginDays + 1}問目の回答</IonCardTitle>
              </IonItem>
              <IonItem>
                <IonLabel>
                  <h2>Q1</h2>
                  {question1 === "確かに" && (
                    <h3 className="answer-type-A">
                      確かに！ or おっしゃる通り！
                    </h3>
                  )}
                  {question1 === "違う" && (
                    <h3 className="answer-type-B">
                      違うかも... or そうではない
                    </h3>
                  )}
                  {question1 === "どちらでもない" && (
                    <h3 className="answer-type-C">どちらとも言えない</h3>
                  )}
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel>
                  <h2>Q2</h2>
                  {question2 === "確かに" && (
                    <h3 className="answer-type-A">
                      確かに！ or おっしゃる通り！
                    </h3>
                  )}
                  {question2 === "違う" && (
                    <h3 className="answer-type-B">
                      違うかも... or そうではない
                    </h3>
                  )}
                  {question2 === "どちらでもない" && (
                    <h3 className="answer-type-C">どちらとも言えない</h3>
                  )}
                </IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel>
                  <h2>Q3</h2>
                  {question3 === "確かに" && (
                    <h3 className="answer-type-A">
                      確かに！ or おっしゃる通り！
                    </h3>
                  )}
                  {question3 === "違う" && (
                    <h3 className="answer-type-B">
                      違うかも... or そうではない
                    </h3>
                  )}
                  {question3 === "どちらでもない" && (
                    <h3 className="answer-type-C">どちらとも言えない</h3>
                  )}
                </IonLabel>
              </IonItem>
            </IonCard>
            <IonButton onClick={() => setShowAlert1(true)} expand="block">
              結果を送信する
            </IonButton>
            <IonAlert
              isOpen={showAlert1}
              onDidDismiss={() => setShowAlert1(false)}
              cssClass="my-custom-class"
              header={"確認！！"}
              message={"結果を送信してもいいですか？"}
              buttons={[
                {
                  text: "Cancel",
                  role: "cancel",
                  cssClass: "secondary",
                  handler: () => {
                    console.log("Confirm Cancel");
                  },
                },
                {
                  text: "Ok",
                  handler: async () => {
                    await updateScore();
                    window.location.href = "/logout";
                  },
                },
              ]}
            />
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export default SendAnswer;

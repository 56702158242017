/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserData = /* GraphQL */ `
  mutation CreateUserData(
    $input: CreateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    createUserData(input: $input, condition: $condition) {
      id
      userId
      userName
      loginDays
      email
      scoreA
      scoreB
      scoreC
      scoreD
      answerLimit
      answerValidation
      status
      sortFlg
      updatedAt
      createdAt
      owner
    }
  }
`;
export const updateUserData = /* GraphQL */ `
  mutation UpdateUserData(
    $input: UpdateUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    updateUserData(input: $input, condition: $condition) {
      id
      userId
      userName
      loginDays
      email
      scoreA
      scoreB
      scoreC
      scoreD
      answerLimit
      answerValidation
      status
      sortFlg
      updatedAt
      createdAt
      owner
    }
  }
`;
export const deleteUserData = /* GraphQL */ `
  mutation DeleteUserData(
    $input: DeleteUserDataInput!
    $condition: ModelUserDataConditionInput
  ) {
    deleteUserData(input: $input, condition: $condition) {
      id
      userId
      userName
      loginDays
      email
      scoreA
      scoreB
      scoreC
      scoreD
      answerLimit
      answerValidation
      status
      sortFlg
      updatedAt
      createdAt
      owner
    }
  }
`;

import React from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { exit, home } from 'ionicons/icons/';
import { Auth } from 'aws-amplify';

async function signOut() {
  await Auth.signOut();
}

const Header: React.FC = () => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton href="/home">
            <IonIcon icon={home} />
          </IonButton>
        </IonButtons>
        <IonTitle>Greed Select3</IonTitle>
        <IonButtons slot="end">
          <IonButton href="/" onClick={signOut}>
              <IonIcon icon={exit} />ログアウト
          </IonButton>
        </IonButtons>
        </IonToolbar>
    </IonHeader>
  )
}

export default Header
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonRow,
  IonCol,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../../components/top/Header";
import "./FirstQuestion.css";

import { questions } from "../../data/questionData";

// localStorage
import { Plugins } from "@capacitor/core";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { listUserDatasLoginDaysOnly } from "../../graphql/customQueries";
import { UserNameByIndexQuery } from "../../API";
const { Storage } = Plugins;

// 質問を取得するロジック (ログイン日数-1) * 3 + 1

const pushAnswerA = async () => {
  await Storage.set({
    key: "answer2",
    value: JSON.stringify({
      answer: "違う",
    }),
  });
  window.location.href = "/third";
};

const pushAnswerB = async () => {
  await Storage.set({
    key: "answer2",
    value: JSON.stringify({
      answer: "確かに",
    }),
  });
  window.location.href = "/third";
};

const pushAnswerC = async () => {
  await Storage.set({
    key: "answer2",
    value: JSON.stringify({
      answer: "どちらでもない",
    }),
  });
  window.location.href = "/third";
};

const SecondQuestion: React.FC = () => {
  const sampleA = "sampleA";
  const buttonPosition = "button-position";
  const [loginDays, setLoginDays] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [catchLoginData, setCatchLoginData] = useState<boolean>(false);
  const questionNo2 = +loginDays * 3 + 1;
  useEffect(() => {
    getLoginDays();
    // eslint-disable-next-line
  }, [userName]);

  const getLoginDays = async () => {
    await Auth.currentUserInfo().then((res) => {
      setUserName(res.username);
    });
    await getLoginData();
    await setCatchLoginData(true);
  };

  const getLoginData = async () => {
    const result = await API.graphql(
      graphqlOperation(listUserDatasLoginDaysOnly, {
        userName: userName,
      })
    );
    if ("data" in result && result.data) {
      const datas = result.data as UserNameByIndexQuery;
      const loginDays = datas.userNameByIndex!.items![0]?.loginDays;
      setLoginDays(loginDays!);
    }
  };

  return (
    <IonPage>
      {!catchLoginData && <div>Loading...</div>}
      {catchLoginData && (
        <>
          <Header />
          <IonContent class="home-content">
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>{questions[questionNo2].title}</IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent>
                    {questions[questionNo2].interpellation}
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div className="space-between"></div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div className={buttonPosition}>
                  <div className={sampleA}>
                    <IonRow>
                      <div className="height-branks"></div>
                    </IonRow>
                    <IonRow>
                      <div className="height-branks"></div>
                    </IonRow>
                    <IonRow>
                      <IonCol size="1.5">
                        <div></div>
                      </IonCol>
                      <IonCol size="4.5">
                        <button onClick={pushAnswerA} className="answerA">
                          <p>違うかも...</p>
                          <p>そうではない</p>
                        </button>
                      </IonCol>
                      <IonCol size="5.5">
                        <button onClick={pushAnswerB} className="answerB">
                          <p>確かに！</p>
                          <p>おっしゃる通り！</p>
                        </button>
                      </IonCol>
                      <IonCol size="0.5">
                        <div></div>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <div className="height-branks"></div>
                    </IonRow>
                    <IonRow>
                      <div className="height-branks"></div>
                    </IonRow>
                    <IonRow>
                      <IonCol size="3.2">
                        <div></div>
                      </IonCol>
                      <IonCol size="6">
                        <button onClick={pushAnswerC} className="answerC">
                          <p>どちらとも言えない</p>
                        </button>
                      </IonCol>
                      <IonCol size="2.8">
                        <div></div>
                      </IonCol>
                    </IonRow>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export default SecondQuestion;

// 質問データもうDynamoDB使うのもったいないし、改修ばっかりで疲れるのでローカルで管理します。
const unit = "問目";

export const questions = [
  {
    title: `1${unit}-質問１`,
    interpellation:
      "新しい商品を買う時『損をしたくない』ので、同じ商品の検索をして、少しでも安いものを探してしまう！",
  },
  {
    title: `1${unit}-質問２`,
    interpellation:
      "いやむしろ、検索自体がめんどくさいので、あまり値段が変わらないなら『欲しい！』と思った感情や自分の直感を重視する",
  },
  {
    title: `1${unit}-質問３`,
    interpellation:
      "ちなみに、今まで買った事がない服を買う時に、自分が着たいと言う感情よりも『周りにどう見えるのか？』をつい考えてしまう",
  },
  {
    title: `2${unit}-質問１`,
    interpellation:
      "『わかる!わかる!』や『僕も一緒!』などの共感される表現よりも『なるほど』や『確かに...』などの客観的な表現を使う人の方が話しやすい!",
  },
  {
    title: `2${unit}-質問２`,
    interpellation:
      "むしろその逆で『私も一緒』や『僕も同じ』などの共感言葉を使ってくれる人の方が話しやすい!",
  },
  {
    title: `2${unit}-質問３`,
    interpellation:
      "正直、口癖なんかはどちらでも良いと思っていて、それよりも深い話や意味のある対話の方が重要だ",
  },
  {
    title: `3${unit}-質問１`,
    interpellation: "自分はどちらかと言うと、ハマりやすく飽き性だ!",
  },
  {
    title: `3${unit}-質問２`,
    interpellation:
      "自分がハマる事よりも周りがハマっているものに、どんどん興味を持ってしまい影響を受けてしまう",
  },
  {
    title: `3${unit}-質問３`,
    interpellation:
      "人とペースを揃えて仕事するよりも自分のペースで進める方がやりやすいと感じてしまう",
  },
  {
    title: `4${unit}-質問１`,
    interpellation:
      "飲み会のお会計の時、ちゃんと割り勘するよりも『気分が良いから!』とついつい奢ってしまう!",
  },
  {
    title: `4${unit}-質問２`,
    interpellation: "気分が良くても、割り勘は割り勘だと割り切っている!",
  },
  {
    title: `4${unit}-質問３`,
    interpellation:
      "実は、飲み会の時によく忘れ物をしてしまう!また不思議なことに、その忘れ物が、自分の手元に帰ってくることが多い!",
  },
  {
    title: `5${unit}-質問１`,
    interpellation:
      "人の話を長時間きく事は、苦痛だと思っているので、要点をまとめて言って貰いたいと思っている",
  },
  {
    title: `5${unit}-質問２`,
    interpellation:
      "いやむしろ、要点だけだと細かい所が気になってしまうので、細かい所までちゃんと言ってもらった方がミスが起きないので助かる",
  },
  {
    title: `5${unit}-質問３`,
    interpellation:
      "相手の話を聞いていると...話の途中で、相手の言いたい事や結論が解ってしまい話を途中で終わらしたくなる事が多々ある",
  },
  {
    title: `6${unit}-質問１`,
    interpellation:
      "最初の話から脱線してしまったり、話がコロコロ変わったとしても割と話についていける方だ",
  },
  {
    title: `6${unit}-質問２`,
    interpellation:
      "実は、コロコロ話が変わる人は、物凄く苦手意識がある(人には、言えないけど...)",
  },
  {
    title: `6${unit}-質問３`,
    interpellation:
      "むしろコミュニケーションは、コロコロ変わったり、脱線するから面白いや楽しいがやって来ると思っている!",
  },
  {
    title: `7${unit}-質問１`,
    interpellation:
      "相手の話している内容に対して『長いな...』と思った時に『要は、何が言いたいのか?』や『結論は、何なのか?』などと思ってしまう",
  },
  {
    title: `7${unit}-質問２`,
    interpellation:
      "いやむしろ、相手の話が長かったとしても自分が知らない内容で興味を持ったりや話が長くてもそこまで気にならない",
  },
  {
    title: `7${unit}-質問３`,
    interpellation:
      "実は、相手の人の話が聞いたことがある内容だったり『長いな...』と思うと『いかに、この会話を終わらそうか?』と考えたり、口を挟んでしまったり、遮ってしまう事がある!",
  },
  {
    title: `8${unit}-質問１`,
    interpellation:
      "『一緒!一緒!』などの周りの意見に共感して決める事よりも...世の中にある事実に基づいた『データ』や『実績』を優先させる",
  },
  {
    title: `8${unit}-質問２`,
    interpellation:
      "いやむしろその逆で、『データ』や『実績』よりも周りの意見に共感して出す選択や決断を優先する",
  },
  {
    title: `8${unit}-質問３`,
    interpellation:
      "正直、両方とも大切なのだが、決めるのは自分であり、責任を追うのも自分だと思っているからこそ、データや結果を重視してしまう",
  },
  {
    title: `9${unit}-質問１`,
    interpellation:
      "周りの人と一緒にいる事は楽しいと思っているが 気がついたら、自分が楽しいを優先してしまっている",
  },
  {
    title: `9${unit}-質問２`,
    interpellation:
      "いやむしろ、自分の楽しいと思う感情よりも周りが笑顔になったり、楽しんでくれる方を優先してしまう",
  },
  {
    title: `9${unit}-質問３`,
    interpellation:
      "そもそも自分が楽しいと思う感覚と人が楽しいと思う感覚にギャップを感じることがある",
  },
  {
    title: `10${unit}-質問１`,
    interpellation: "『変わっているね』と言われるのは、褒め言葉だ",
  },
  {
    title: `10${unit}-質問２`,
    interpellation: "むしろ『変わっているね』と言われるのは悪口だ",
  },
  {
    title: `10${unit}-質問３`,
    interpellation:
      "正直『変わっているね』と言われ過ぎてなんとも思わなくなった!",
  },
  {
    title: `11${unit}-質問１`,
    interpellation:
      "期日内に終わらせるよりも、期待以上の質やクウォリティーにする為に時間をオーバーする事がある",
  },
  {
    title: `11${unit}-質問２`,
    interpellation:
      "いやむしろ、期日や時間をオーバーするくらいなら、質やクウォリティーよりも決められた期日をちゃんと守るべきである",
  },
  {
    title: `11${unit}-質問３`,
    interpellation:
      "今までの経験上、期日も守りながら期待以上の質に仕上げてきた自負がある",
  },
  {
    title: `12${unit}-質問１`,
    interpellation:
      "自分は、友達や知り合いと2人の空間になった時、無言が続いていくと、ストレスを感じやすい",
  },
  {
    title: `12${unit}-質問２`,
    interpellation:
      "いやむしろ、無言だったとしても比較的にストレスを感じにくい方だと思っている",
  },
  {
    title: `12${unit}-質問３`,
    interpellation:
      "改めて考えると...話に間が合ったり、無言の時間が長くなると自分から話してしまう傾向がある",
  },
  {
    title: `13${unit}-質問１`,
    interpellation:
      "モチベーションを高く上げていくよりもモチベーションを一定に保ち続ける方が難しい!",
  },
  {
    title: `13${unit}-質問２`,
    interpellation:
      "その逆で、モチベーションが低い時、意図的にモチベーションを上げる方が難しいと思っている!",
  },
  {
    title: `13${unit}-質問３`,
    interpellation:
      "そもそもモチベーション自体が、自分自身や仕事に与える影響は、そこまで大きくないと考えている",
  },
  {
    title: `14${unit}-質問１`,
    interpellation: "周りからは、もっと人の気持ちを考えた方が良いと言われる",
  },
  {
    title: `14${unit}-質問２`,
    interpellation: "その逆で、人の気持ちを汲み取るのが上手だと言われる",
  },
  {
    title: `14${unit}-質問３`,
    interpellation:
      "そもそも人の気持ちを考えても、正解や答えがないので、考えるだけ無駄だと思ってしまう",
  },
  {
    title: `15${unit}-質問１`,
    interpellation: "ズバリ!人と同じよりも人と違う方が良いと思っている",
  },
  {
    title: `15${unit}-質問２`,
    interpellation:
      "多数決の時、自分の意見よりも人数が多い方に気持ちが傾いてしまい、人数が多い方を選んでしまう",
  },
  {
    title: `15${unit}-質問３`,
    interpellation:
      "自分が持っているセンスや感覚が伝わらなくて共感が得られにくく、周りに伝わらないストレスを最近、感じた事がある",
  },
  {
    title: `16${unit}-質問１`,
    interpellation:
      "自分が新しい商品を購入した時、説明書を読むのがめんどくさくて、とりあえず使ってみて、その後に解らなかったり、どうにもならない時に渋々、説明書を読むパターンが多い!",
  },
  {
    title: `16${unit}-質問２`,
    interpellation:
      "むしろ、使い方を間違ってしまっては困るので、マニュアルや説明書などの使い方を最低限理解したいので、最初に説明書を読んでから商品を使う!",
  },
  {
    title: `16${unit}-質問３`,
    interpellation:
      "実は、最初から説明書を読むよりも実際に使っていった方が早く覚えたり、たまに、独自のやり方を発見する事があった!",
  },
  {
    title: `17${unit}-質問１`,
    interpellation:
      "自分が抱える仕事が多くなりすぎた時に、物事の繋がりなどを考えて、仕事の効率性を高めた経験がある!",
  },
  {
    title: `17${unit}-質問２`,
    interpellation:
      "いやむしろ、頭の中で色々と考えるよりもやっていく中で、ちょっとずつでも改善をして、体に覚えさせた方がいいと思っている",
  },
  {
    title: `17${unit}-質問３`,
    interpellation:
      "そもそも頭で考えているのは、自分のことよりも周りの人や自分に関わってくれている人の仕事に対して考えている事の方が多いかもしれない!",
  },
  {
    title: `18${unit}-質問１`,
    interpellation:
      "仕事は、自分のペースで進めると怠けるので、人と一緒にやっていきたい",
  },
  {
    title: `18${unit}-質問２`,
    interpellation:
      "いやむしろ、人に合わせるのではなく、自分のペースで進めたい",
  },
  {
    title: `18${unit}-質問３`,
    interpellation:
      "実は、自分のペースで進めたいと思って仕事をしているが、ふと気が付くと...周りの人のペースに合わせてしまっている自分がいる",
  },
  {
    title: `19${unit}-質問１`,
    interpellation:
      "自分の人生に必要なものは、ワクワクする気持ちよりも世の中にある正確な情報だ",
  },
  {
    title: `19${unit}-質問２`,
    interpellation:
      "確かに正確な情報も重要だが、ワクワクする気持ちの方が、はるかに大事だ!",
  },
  {
    title: `19${unit}-質問３`,
    interpellation:
      "言い方は悪いが、ワクワクする気持ちが正確な情報ですら、曇らせてしまう危険性がある!",
  },
  {
    title: `20${unit}-質問１`,
    interpellation:
      "会話が表面的だったり、声が大きい人より、無口で無愛想で、淡々と話す人の方が、まだマシだ",
  },
  {
    title: `20${unit}-質問２`,
    interpellation:
      "いやむしろ、意味深に話して来る人だったり、話に裏表がありそうな人よりも会話が表面的だったり、声が大きい人の方がマシだ",
  },
  {
    title: `20${unit}-質問３`,
    interpellation:
      "人には決して言えないが、そもそもお喋りや雑談をすること自体は、時間の無駄だと思っている",
  },
  {
    title: `21${unit}-質問１`,
    interpellation: "何故だかわからないけど、自分には自信がある",
  },
  {
    title: `21${unit}-質問２`,
    interpellation: "いやむしろ、自分にはそこまで自信はないと思っている",
  },
  {
    title: `21${unit}-質問３`,
    interpellation:
      "自分でも自信があると思っているが、『その自信どっからやってくるの?』と 周りの人から何度か言われた経験がある",
  },
  {
    title: `22${unit}-質問１`,
    interpellation:
      "『今日は、何をするのか?』が決まっている仕事よりも『今日、自分で何をするのか?』を考えていく仕事の方がテンションが上がる",
  },
  {
    title: `22${unit}-質問２`,
    interpellation:
      "その逆で、すでに決まっている仕事だったり『人から何をして欲しい』と言われてやって行く仕事の方が、自分には合っている",
  },
  {
    title: `22${unit}-質問３`,
    interpellation:
      "正解や答えがある仕事ではなく、むしろ正解がない仕事内容の方が、ワクワクしたり面白いと思ってしまいがちだ",
  },
  {
    title: `23${unit}-質問１`,
    interpellation: "自分は、評価をされる側よりも評価をする側になりたい!",
  },
  {
    title: `23${unit}-質問２`,
    interpellation: "いやむしろ、人を評価したり、されたくない!",
  },
  {
    title: `23${unit}-質問３`,
    interpellation:
      "誰にも言えないが、自分は一般的な人よりも能力が高いと思っている!",
  },
  {
    title: `24${unit}-質問１`,
    interpellation:
      "無言で黙々と仕事をするよりも雑談やコミュニケーションを取りながら取り組みたい",
  },
  {
    title: `24${unit}-質問２`,
    interpellation:
      "誰にも言えないが、仕事中のお喋りや雑談を取る人に対して、それが原因で、時間内に終わらない事があるので、黙々と仕事をした方が時間に間に合うのに...と思ってしまう",
  },
  {
    title: `24${unit}-質問３`,
    interpellation:
      "実は、雑談やコミュニケーションを取っている理由には、関係性を高めている部分もあると思っている",
  },
  {
    title: `25${unit}-質問１`,
    interpellation:
      "物事を考える時、過去に起こった出来事や事実から考える事よりも 未来を想像して考える事の方が多いと思う",
  },
  {
    title: `25${unit}-質問２`,
    interpellation:
      "いやむしろ、過去に起こった出来事や事実から考える事の方が多い",
  },
  {
    title: `25${unit}-質問３`,
    interpellation:
      "あまり過去や未来に囚われるのではなく、自由に発想して、物事を考えた方が良いと思っている",
  },
  {
    title: `26${unit}-質問１`,
    interpellation:
      "メンバーと共に成し遂げる充実感よりも個人だけの成果になったとしても結果を重視してしまう傾向がある",
  },
  {
    title: `26${unit}-質問２`,
    interpellation:
      "確かに、結果は重要かもしれないが、それだけを求められると、どんどんやる気を失ってしまう",
  },
  {
    title: `26${unit}-質問３`,
    interpellation:
      "正直、働いているメンバーのやる気よりも 成果報酬などのお金を優先してしまう",
  },
  {
    title: `27${unit}-質問１`,
    interpellation:
      "お客様やスタッフなど、人の心に興味があるが、どちらかと言うと...人(自分やスタッフ)が持つ可能性や未来に興味がある",
  },
  {
    title: `27${unit}-質問２`,
    interpellation: "いやむしろ、人が持つ心(心理)に興味がある!",
  },
  {
    title: `27${unit}-質問３`,
    interpellation:
      "実は、他人の可能性や未来よりもどうしても『自分の可能性をどうやって活かすか?』や自分の将来を想像してしまう!",
  },
  {
    title: `28${unit}-質問１`,
    interpellation: "常識やルールは、破るためにある",
  },
  {
    title: `28${unit}-質問２`,
    interpellation: "いやむしろ、常識やルールは、守るためにある",
  },
  {
    title: `28${unit}-質問３`,
    interpellation: "そもそも常識こそが、非常識だ!",
  },
  {
    title: `29${unit}-質問１`,
    interpellation:
      "1つ1つ積み上げていく事で得られる能力(鍛錬) よりも物事の本質を見抜く能力の方が必要だ",
  },
  {
    title: `29${unit}-質問２`,
    interpellation:
      "確かに、本質を見抜く事も大切だが、自ら積み上げた努力は、自分を裏切らない",
  },
  {
    title: `29${unit}-質問３`,
    interpellation:
      "努力は自分を裏切らないかもしれないが、本質を見抜く事で、人から裏切られない方を優先する",
  },
  {
    title: `30${unit}-質問１`,
    interpellation: "苦手なタイプは、周りの事をあまり考えない人だ",
  },
  {
    title: `30${unit}-質問２`,
    interpellation: "実は、その逆で誰にでも愛想を振りまく八方美人タイプだ!",
  },
  {
    title: `30${unit}-質問３`,
    interpellation:
      "自分ではそう思っていないが、周りから良くも悪くも『世話焼き』や『お節介』と言われてしまう!",
  },
];

import React, { useState, useEffect } from "react";
import { IonContent, IonPage, IonButton, IonIcon, IonCard } from "@ionic/react";
import { arrowForward } from "ionicons/icons";
import "./Tutorial.css";
import Image from "../../assets/intro5.png";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { UserNameByIndexQuery } from "../../API";
import { getUserDataAtHomePage } from "../../graphql/customQueries";
import * as mutations from "../../graphql/mutations";

const answerLimit = 5;

const Slide5: React.FC = (history) => {
  const [userName, setUserName] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const authUser = async () => {
    await Auth.currentUserInfo().then((res) => {
      setUserName(res.username);
    });
    await getUserData();
  };

  useEffect(() => {
    authUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);

  const createAndGetUserData = async () => {
    await updateUserData();
    await startApp();
  };

  const getUserData = async () => {
    const result = await API.graphql(
      graphqlOperation(getUserDataAtHomePage, {
        userName: userName,
      })
    );
    if ("data" in result && result.data) {
      const posts = result.data as UserNameByIndexQuery;
      const id = posts.userNameByIndex?.items![0]?.id;
      setUserId(id!);
    }
  };

  const userScores = {
    id: userId,
    scoreA: 5,
    scoreB: 5,
    scoreC: 5,
    scoreD: 5,
    loginDays: "0",
    answerLimit: answerLimit,
    answerValidation: true,
  };

  const updateUserData = async () => {
    await API.graphql({
      query: mutations.updateUserData,
      variables: {
        input: userScores,
      },
    });
  };
  const startApp = async () => {
    window.location.href = "https://forms.gle/7KVGKZuoi8hNuv8A9";
  };

  return (
    <IonPage>
      <IonContent fullscreen class="home-content">
        <IonCard class="explanation-slide">
          <img src={Image} alt="イントロ画像" />
          <IonButton fill="clear" onClick={createAndGetUserData}>
            5/5 ユーザー登録ページへ
            <IonIcon slot="end" icon={arrowForward} />
          </IonButton>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Slide5;

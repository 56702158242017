import {
  IonApp,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonCard,
  IonInput,
  IonItem,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import SideBar from "../../components/admin/SideBar";
import { Auth } from "aws-amplify";

type Password = "password" | "text";

const CertificationAdmin: React.FC = () => {
  const [username, setUserName] = useState("");
  const [adminUserName, setAdminUserName] = useState("");

  const [code, setCode] = useState("");

  async function confirmSignUp() {
    try {
      await Auth.confirmSignUp(username, code);
      await nextPage();
    } catch (error) {
      console.log("error confirming sign up", error);
    }
  }

  function nextPage() {
    window.location.href = "/";
  }

  // userNameを取得するタイミングでauthuserを実行
  useEffect(() => {
    authuser();
    // eslint-disable-next-line
  }, [adminUserName]);

  const authuser = async () => {
    try {
      await Auth.currentUserInfo().then((res) => {
        if (res.username !== "createuser001") {
          window.location.href = "/";
        } else {
          setAdminUserName(res.username);
          console.log(res.username);
        }
      });
    } catch {
      window.location.href = "/";
    }
  };

  return (
    <IonApp>
      <IonContent id="content" className="admin-background">
        {!adminUserName && <div>Loading...</div>}
        {adminUserName && (
          <IonGrid>
            <IonRow>
              <IonCol size="0.5">
                <SideBar />
              </IonCol>
              <IonCol size="11.5">
                <IonCard class="admin-create-user-card">
                  <div className="card-blank-space"></div>
                  <IonItem className="admin-input-item">
                    <IonInput
                      name="username"
                      type="text"
                      data-testid="input-username"
                      class="admin-input-value"
                      placeholder="ユーザーネーム(ID)"
                      onIonChange={(e: any) => setUserName(e.target.value)}
                    ></IonInput>
                  </IonItem>
                  <div className="card-blank-space-10px"></div>
                  <IonItem class="admin-input-item">
                    <IonInput
                      name="text"
                      class="admin-input-value"
                      placeholder="認証コード"
                      onIonChange={(e: any) => setCode(e.target.value)}
                    ></IonInput>
                  </IonItem>
                  <IonRow>
                    <IonCol>
                      <p data-testid="notes" className="instructions">
                        ※IDとPW 共に<br></br>半角英語のみ、スペースなし
                      </p>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <IonButton
                        data-testid="login-btn"
                        title="Add Todo"
                        className="fancy-button"
                        onClick={confirmSignUp}
                      >
                        認証
                      </IonButton>
                    </IonCol>
                  </IonRow>

                  <div className="card-blank-space"></div>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonApp>
  );
};

export default CertificationAdmin;

import React, { useEffect, useState } from "react";
import {
  withStyles,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  IonApp,
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
} from "@ionic/react";

import { CSVLink } from "react-csv";
import { API, Auth, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import { createdAtSort } from "../../graphql/queries";
import { CreatedAtSortQuery } from "../../API";

import "./Admin.css";
import SideBar from "../../components/admin/SideBar";
import TablePaginationActions from "../../components/admin/TablePagenationActions";

const useStyles2 = makeStyles({
  table: {
    minWidth: 300,
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

type UserDatas = {
  id: string;
  userId: string;
  userName: string;
  loginDays: string;
  scoreA: number;
  scoreB: number;
  scoreC: number;
  scoreD: number;
  createdAt: string;
  updatedAt: string;
  status: string;
};

const CustomPaginationActionsTable: React.FC = (props) => {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [adminUserName, setAdminUserName] = useState("");
  const [userDatas, setUserDatas] = useState<UserDatas[]>([]);

  // userNameを取得するタイミングでauthuserを実行
  useEffect(() => {
    authuser();
    getUserData();
    // eslint-disable-next-line
  }, [adminUserName]);

  const getUserData = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(createdAtSort, {
          sortFlg: 1,
          sortDirection: "DESC",
        })
      );
      console.log(result);
      if ("data" in result && result.data) {
        const posts = result.data as CreatedAtSortQuery;
        if (posts.createdAtSort) {
          const postArray = posts.createdAtSort.items as UserDatas[];
          // statusの値に何かしら入ると削除として認識します。
          const postData = postArray.filter(
            (pickupUser) => pickupUser.status !== "delete"
          );
          setUserDatas(postData);
          if (posts.createdAtSort.items?.length! < 100) {
            setRowsPerPage(posts.createdAtSort.items?.length!);
          } else {
            setRowsPerPage(100);
          }
        }
      }
    } catch (error) {
      window.location.href = "/";
    }
  };

  const authuser = async () => {
    try {
      await Auth.currentUserInfo().then((res) => {
        if (res.username !== "createuser001") {
          window.location.href = "/";
        } else {
          setAdminUserName(res.username);
          console.log(res.username);
        }
      });
    } catch {
      window.location.href = "/";
    }
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, userDatas.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteUser = async (userId: any, e: any) => {
    const result = window.confirm("削除してもよろしいですか？");

    if (result) {
      e.preventDefault();
      const deleteUser = {
        id: userId,
        status: "delete",
      };
      await API.graphql({
        query: mutations.updateUserData,
        variables: { input: deleteUser },
      });
      await window.location.reload();
    } else {
      alert("キャンセルされました");
    }
  };

  return (
    <IonApp>
      <IonContent id="content" className="admin-background">
        {!adminUserName && <div>Loading...</div>}
        {adminUserName && (
          <IonGrid>
            <IonRow>
              <IonCol size="0.5">
                <SideBar />
              </IonCol>
              <IonCol size="11.5">
                <IonButton class="csv-download">
                  <CSVLink data={userDatas} style={{ color: "#fff" }}>
                    CSV ダウンロード
                  </CSVLink>
                </IonButton>
                <IonCard class="table-margin">
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="custom pagination table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>UserName</StyledTableCell>
                          <StyledTableCell>Score A</StyledTableCell>
                          <StyledTableCell>Score B</StyledTableCell>
                          <StyledTableCell>Score C</StyledTableCell>
                          <StyledTableCell>Score D</StyledTableCell>
                          <StyledTableCell>Total Score</StyledTableCell>
                          <StyledTableCell>Status Delete</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? userDatas.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : userDatas
                        ).map((test) => (
                          <TableRow key={test.userName}>
                            <TableCell component="th" scope="row">
                              {test.userName}
                            </TableCell>
                            <TableCell style={{ width: 360 }} align="left">
                              {test.scoreA}
                            </TableCell>
                            <TableCell style={{ width: 360 }} align="left">
                              {test.scoreB}
                            </TableCell>
                            <TableCell style={{ width: 360 }} align="left">
                              {test.scoreC}
                            </TableCell>
                            <TableCell style={{ width: 360 }} align="left">
                              {test.scoreD}
                            </TableCell>
                            <TableCell style={{ width: 360 }} align="left">
                              {test.scoreA +
                                test.scoreB +
                                test.scoreC +
                                test.scoreD}
                            </TableCell>
                            <TableCell style={{ width: 100 }} align="left">
                              <svg
                                onClick={(e) => deleteUser(test.id, e)}
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </TableCell>
                          </TableRow>
                        ))}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              25,
                              { label: "All", value: -1 },
                            ]}
                            colSpan={3}
                            count={userDatas.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: { "aria-label": "test per page" },
                              native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonApp>
  );
};
export default CustomPaginationActionsTable;

export const pattern = (days: number, score1: number, score2: number) => {
  let pattern: string;
  let plusScoreA: number = 0;
  let plusScoreB: number = 0;
  let plusScoreC: number = 0;
  let plusScoreD: number = 0;
  if (+days === 1) {
    pattern = 'AD';
    plusScoreA = score1;
    plusScoreD = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 2) {
    pattern = 'AC';
    plusScoreA = score1;
    plusScoreC = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 3) {
    pattern = 'DC';
    plusScoreD = score1;
    plusScoreC = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 4) {
    pattern = 'DB';
    plusScoreD = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 5) {
    pattern = 'AB';
    plusScoreA = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 6) {
    pattern = 'CB';
    plusScoreC = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 7) {
    pattern = 'AD';
    plusScoreA = score1;
    plusScoreD = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 8) {
    pattern = 'AC';
    plusScoreA = score1;
    plusScoreC = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 9) {
    pattern = 'DC';
    plusScoreD = score1;
    plusScoreC = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 10) {
    pattern = 'DB';
    plusScoreD = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 11) {
    pattern = 'AB';
    plusScoreA = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 12) {
    pattern = 'CB';
    plusScoreC = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 13) {
    pattern = 'AD';
    plusScoreA = score1;
    plusScoreD = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 14) {
    pattern = 'AC';
    plusScoreA = score1;
    plusScoreC = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 15) {
    pattern = 'DC';
    plusScoreD = score1;
    plusScoreC = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 16) {
    pattern = 'DB';
    plusScoreD = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 17) {
    pattern = 'AB';
    plusScoreA = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 18) {
    pattern = 'CB';
    plusScoreC = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 19) {
    pattern = 'AD';
    plusScoreA = score1;
    plusScoreD = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 20) {
    pattern = 'AC';
    plusScoreA = score1;
    plusScoreC = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 21) {
    pattern = 'DC';
    plusScoreD = score1;
    plusScoreC = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 22) {
    pattern = 'DB';
    plusScoreD = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 23) {
    pattern = 'AB';
    plusScoreA = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 24) {
    pattern = 'CB';
    plusScoreC = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 25) {
    pattern = 'DA';
    plusScoreD = score1;
    plusScoreA = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 26) {
    pattern = 'AC';
    plusScoreA = score1;
    plusScoreC = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 27) {
    pattern = 'DC';
    plusScoreD = score1;
    plusScoreC = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 28) {
    pattern = 'DB';
    plusScoreD = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 29) {
    pattern = 'AB';
    plusScoreA = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
  if (+days === 30) {
    pattern = 'CB';
    plusScoreC = score1;
    plusScoreB = score2;
    return { pattern: pattern, plusScoreA: plusScoreA, plusScoreB: plusScoreB, plusScoreC: plusScoreC, plusScoreD: plusScoreD};
  }
}
import { IonButton, IonIcon } from "@ionic/react";
import { personAddOutline, statsChartOutline } from "ionicons/icons";
import React from "react";
import './SideBar.css';

const SideBar = () => {
  return (
    <div className="side-bar">
      <IonButton href="/admin-add-user" className="admin-add-user">
        <IonIcon icon={personAddOutline} className="admin-add-user-icon"></IonIcon>
      </IonButton>
      <IonButton href="/admin" className="analytics-user">
        <IonIcon icon={statsChartOutline} className="admin-analytics-user-icon"></IonIcon>
      </IonButton>
    </div>
  );
};

export default SideBar;

import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  isPlatform,
} from "@ionic/react";
import Amplify, { Auth } from "aws-amplify";
import React, { useState } from "react";
import awsmobile from "../../aws-exports";
import "./LoginHome.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import { eye, eyeOff } from "ionicons/icons";

Amplify.configure(awsmobile);
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

type Password = "password" | "text";

const LoginHome: React.FC = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [adminUsername, setAdminUserName] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [message, setMessage] = useState("");
  const [passwordType, setPasswordType] = useState<Password>("password");
  const [passwordIcon, setPasswordIcon] = useState(eye);

  function togglePasswordMode() {
    if (passwordType === "password") {
      setPasswordType("text");
      setPasswordIcon(eyeOff);
    } else if (passwordType === "text") {
      setPasswordType("password");
      setPasswordIcon(eye);
    } else {
      setPasswordType("password");
      setPasswordIcon(eye);
    }
  }

  async function signIn() {
    if (!username || !password) {
      setMessage("※ユーザーネームとパスワードが正しく入力されていません");
    } else {
      try {
        await Auth.signIn(username, password);
        window.location.href = "/home";
      } catch (error) {
        setMessage("※ユーザーネームとパスワードが間違っています");
      }
    }
  }

  async function adminSignIn() {
    if (
      adminUsername === "createuser001" &&
      adminPassword === "Senperfi0509!"
    ) {
      try {
        await Auth.signIn(adminUsername, adminPassword);
        window.location.href = "/admin";
      } catch (error) {
        setMessage("＊管理者ユーザー名とパスワードが正しく入力されていません");
      }
    } else {
      setMessage("＊管理者ユーザー名とパスワードが正しく入力されていません");
    }
  }

  return (
    <IonPage>
      <IonContent class="background-image-login">
        <IonGrid>
          {isPlatform("mobile") && (
            <>
              <IonRow>
                <IonCol>
                  <IonCard class="login-card">
                    <IonRow>
                      <IonCol>
                        <p className="error-message">{message}</p>
                      </IonCol>
                    </IonRow>
                    {!message && <div className="card-blank-space"></div>}
                    <IonItem class="input-item">
                      <IonInput
                        name="username"
                        type="text"
                        data-testid="input-username"
                        class="input-value"
                        placeholder="ユーザーネーム(ID)"
                        onIonChange={(e: any) => setUserName(e.target.value)}
                      ></IonInput>
                    </IonItem>
                    <div className="card-blank-space-10px"></div>
                    <IonItem class="input-item">
                      <IonInput
                        name="password"
                        type={passwordType}
                        class="input-value"
                        placeholder="パスワード(PW)"
                        onIonChange={(e: any) => setPassword(e.target.value)}
                      ></IonInput>
                      <IonIcon
                        icon={passwordIcon}
                        item-right
                        onClick={togglePasswordMode}
                      ></IonIcon>
                    </IonItem>
                    <IonRow>
                      <IonCol>
                        <p data-testid="notes" className="instructions">
                          ※IDとPW 共に<br></br>半角英語のみ、スペースなし
                        </p>
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol>
                        <IonButton
                          data-testid="login-btn"
                          title="Add Todo"
                          className="fancy-button"
                          onClick={signIn}
                        >
                          ログイン
                        </IonButton>
                      </IonCol>
                    </IonRow>

                    <div className="card-blank-space"></div>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <div></div>
                </IonCol>
              </IonRow>
            </>
          )}
          {!isPlatform("mobile") && (
            <>
              <IonRow>
                <IonCol>
                  <IonCard class="admin-login-card">
                    <IonRow>
                      <IonCol>
                        <h2>管理者ログイン画面</h2>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <p className="error-message">{message}</p>
                      </IonCol>
                    </IonRow>
                    {!message && <div className="card-blank-space"></div>}
                    <IonItem class="input-item">
                      <IonInput
                        name="username"
                        type="text"
                        data-testid="input-username"
                        class="input-value"
                        placeholder="ユーザーネーム(ID)"
                        onIonChange={(e: any) =>
                          setAdminUserName(e.target.value)
                        }
                      ></IonInput>
                    </IonItem>
                    <div className="card-blank-space-10px"></div>
                    <IonItem class="input-item">
                      <IonInput
                        name="password"
                        type={passwordType}
                        class="input-value"
                        placeholder="パスワード(PW)"
                        onIonChange={(e: any) =>
                          setAdminPassword(e.target.value)
                        }
                      ></IonInput>
                      <IonIcon
                        icon={passwordIcon}
                        item-right
                        onClick={togglePasswordMode}
                      ></IonIcon>
                    </IonItem>
                    <IonRow>
                      <IonCol>
                        <p data-testid="notes" className="instructions">
                          ※IDとPW 共に<br></br>半角英語のみ、スペースなし
                        </p>
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol>
                        <IonButton
                          data-testid="login-btn"
                          title="Add Todo"
                          className="fancy-button"
                          onClick={adminSignIn}
                        >
                          ログイン
                        </IonButton>
                      </IonCol>
                    </IonRow>

                    <div className="card-blank-space"></div>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <div></div>
                </IonCol>
              </IonRow>
            </>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default LoginHome;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:fdbe6ac5-ee97-4b66-abae-3c785af5ced8",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_FqcDN9vnO",
    "aws_user_pools_web_client_id": "5ult15g8s92dl025telh67uqkq",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://3gngl56m5zb4xl7njtr6ibwyq4.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "greedislands-20210218180203-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d3e0emq53abi4b.cloudfront.net"
};


export default awsmobile;

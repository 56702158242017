import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonPage,
  IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./Home.css";
import Header from "../../components/top/Header";

import Amplify from "@aws-amplify/core";
import awsmobile from "../../aws-exports";

import { API, Auth, graphqlOperation } from "aws-amplify";
import { UserNameByIndexQuery } from "../../API";
// import { userNameByIndex } from "../../graphql/queries";
import { getUserDataAtHomePage } from "../../graphql/customQueries";

import { isPlatform } from "@ionic/react";

Amplify.configure(awsmobile);

// プラットフォーム取得
// const platforms = getPlatforms();

const Home: React.FC<any> = () => {
  const [userName, setUserName] = useState<string>("");
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loginDays, setLoginDays] = useState<string>("");
  const [catchLoginData, setCatchLoginData] = useState<boolean>(false);
  const [isLimit, setIsLimit] = useState<boolean>(false);
  const [createStatus, setCreateStatus] = useState<boolean>(false);

  // userNameを取得するタイミングでauthuserを実行
  useEffect(() => {
    authuser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);

  const authuser = async () => {
    await Auth.currentUserInfo().then((res) => {
      setUserName(res.username);
    });
    await getUserData();
    await setCatchLoginData(true);
  };

  const getUserData = async () => {
    let updateDateString;
    let updateDate;
    // 現在時刻
    let checkTime = new Date();

    const result = await API.graphql(
      graphqlOperation(getUserDataAtHomePage, {
        userName: userName,
      })
    );
    if ("data" in result && result.data) {
      const posts = result.data as UserNameByIndexQuery;
      console.log(posts);
      if (posts.userNameByIndex!.items![0]?.scoreA! !== null) {
        setCreateStatus(true);
      }
      if (!posts.userNameByIndex!.items![0]?.id!) {
        setIsLoggedIn(false);
      } else if (
        !posts.userNameByIndex!.items![0]?.answerValidation! &&
        posts.userNameByIndex!.items![0]?.loginDays !== "30"
      ) {
        // DynamoDBからデータを取得して時間に変換
        setIsLoggedIn(true);
        updateDateString = posts.userNameByIndex!.items![0]?.updatedAt;
        updateDate = new Date(updateDateString!);
        const loginHours = 7;
        /**
         * 当日15時まで回答が終わっていてその日の15時にログイン制御が解除される仕組み
         */
        const updateDateCheck = new Date(
          updateDate.getFullYear(),
          updateDate.getMonth(),
          updateDate.getDate()
        );
        const todayCheck = new Date(
          checkTime.getFullYear(),
          checkTime.getMonth(),
          checkTime.getDate()
        );
        const loginValidationCheckTime = new Date(
          updateDate.getFullYear(),
          updateDate.getMonth(),
          updateDate.getDate(),
          loginHours
        );
        const loginValidationTodayTime = new Date(
          checkTime.getFullYear(),
          checkTime.getMonth(),
          checkTime.getDate(),
          loginHours
        );
        // 最終回答日と今日の日付が同じとき
        const sameDays = updateDateCheck.getTime() === todayCheck.getTime();
        // 最終回答時刻が15時を回っていないかを確認
        const sameDaysUpdatedAtValidationCheck =
          updateDate < loginValidationCheckTime;
        // 現在時刻がその日の15時以降であることを確認
        const sameDaysTodayValidationCheck =
          checkTime > loginValidationCheckTime;
        if (
          sameDays &&
          sameDaysUpdatedAtValidationCheck &&
          sameDaysTodayValidationCheck
        ) {
          console.log(
            "ログイン日と回答日は同じですが、7時より前に回答しています。"
          );
          setIsLimit(false);
          setIsLoggedIn(true);
        } else {
          setIsLimit(true);
        }
        // 最終回答日と今日の日付のズレが１日あるとき １日は86400000秒です
        const diffDays = todayCheck.getTime() - updateDateCheck.getTime();
        if (diffDays === 86400000) {
          if (checkTime < loginValidationTodayTime) {
          } else {
            setIsLimit(false);
            setIsLoggedIn(true);
          }
        } else if (diffDays > 86400000) {
          setIsLimit(false);
          setIsLoggedIn(true);
        }
      } else {
        setLoginDays(posts.userNameByIndex!.items![0]?.loginDays!);
        setIsLoggedIn(true);
      }
    }
  };

  // ifチェックのためのログイン情報取得
  // 具体的にはDynamoDBからユーザーデータが取得できるかどうかで判断します
  return (
    <IonPage>
      {!catchLoginData && <div>Loading...</div>}
      {catchLoginData && (
        <IonContent>
          <Header />
          <IonContent class="home-content">
            {isLoggedIn && (
              <>
                <IonRow>
                  <IonCol>
                    <IonCard>
                      <IonCardContent>
                        <p>『質問文を読んで、３択から選択』</p>
                        <br />
                        <p>
                          出来るだけ<span className="color-red">『直感』</span>
                          で、お答えください
                        </p>
                        <br />
                        <p>ただ、どうしても迷った時は、</p>
                        <p>
                          <span className="color-red">
                            『過去の自分どうだった』
                          </span>
                          ではなく
                        </p>
                        <p>
                          <span className="color-red">
                            『今の自分ならどれを選択するか？』
                          </span>
                          で
                        </p>
                        <p>選んでいただければと思います。</p>
                        <br />
                        <p>※選択した回答内容が、</p>
                        <p>他人に漏れる事は、決してありません。</p>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div className="space-between"></div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {!createStatus && (
                      <IonButton expand="block" href="/user-registration">
                        あたらしくはじめる
                      </IonButton>
                    )}
                    {createStatus &&
                      loginDays !== "30" &&
                      isLoggedIn &&
                      !isLimit && (
                        <IonButton expand="block" href="/first">
                          回答をはじめる
                        </IonButton>
                      )}
                    {loginDays !== "30" &&
                      isLoggedIn &&
                      isLimit &&
                      createStatus && (
                        <IonButton expand="block">本日は、ここまで</IonButton>
                      )}
                    {loginDays === "30" && (
                      <IonButton expand="block" href="/result">
                        結果を確認する
                      </IonButton>
                    )}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div className="space-between"></div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonCard>
                      <IonCardContent>
                        <p>
                          1.日々の診断のやり方 →{" "}
                          <a
                            className="color-red"
                            href="https://note.com/info_greed_s3/n/n3e7503d3b762"
                          >
                            こちらをクリック
                          </a>
                        </p>
                        <p>（取り扱い説明書）</p>
                        <p>
                          2欲求タイプの解説 →{" "}
                          <a
                            className="color-red"
                            href="https://note.com/info_greed_s3/n/na43793a979ba"
                          >
                            こちらをクリック
                          </a>
                        </p>
                        <br />
                        <p>
                          3.効き欲の活用方法 →{" "}
                          <a
                            className="color-red"
                            href="https://note.com/info_greed_s3/n/neff80aeff441"
                          >
                            こちらをクリック
                          </a>
                        </p>
                        <br />
                        <p>
                          ※困ったことがあれば →{" "}
                          <a
                            className="color-red"
                            href="https://lin.ee/GCvJSif"
                          >
                            こちらをクリック
                          </a>
                        </p>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </>
            )}

            {!isPlatform("mobile") && (
              <IonRow>
                <IonCol>
                  <IonButton expand="block" href="/admin">
                    管理画面へ遷移する
                  </IonButton>
                </IonCol>
              </IonRow>
            )}
          </IonContent>
        </IonContent>
      )}
    </IonPage>
  );
};

export default Home;

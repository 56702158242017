import {
  IonCard,
  IonCardContent,
  IonContent,
  IonIcon,
  IonPage,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import Header from "../../components/top/Header";

// localStorage
import { API, Auth, graphqlOperation } from "aws-amplify";
import { listUserDatasLoginDaysOnly } from "../../graphql/customQueries";
import { UserNameByIndexQuery } from "../../API";
import { home } from "ionicons/icons";

const Logout: React.FC = () => {
  const [loginDays, setLoginDays] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [catchLoginData, setCatchLoginData] = useState<boolean>(false);

  useEffect(() => {
    getLoginDays();
    // eslint-disable-next-line
  }, [userName]);

  const getLoginDays = async () => {
    await Auth.currentUserInfo().then((res) => {
      setUserName(res.username);
    });
    await getLoginData();
    await setCatchLoginData(true);
  };

  const getLoginData = async () => {
    const result = await API.graphql(
      graphqlOperation(listUserDatasLoginDaysOnly, {
        userName: userName,
      })
    );
    if ("data" in result && result.data) {
      const datas = result.data as UserNameByIndexQuery;
      const loginDays = datas.userNameByIndex!.items![0]?.loginDays;
      setLoginDays(loginDays!);
    }
  };

  return (
    <IonPage>
      {!catchLoginData && <div>Loading...</div>}
      {catchLoginData && (
        <>
          <Header />
          <IonContent class="home-content">
            <IonCard class="logout">
              {loginDays !== "30" && (
                <>
                  <IonCardContent>
                    回答頂き、ありがとうございます！
                  </IonCardContent>
                  <IonCardContent>
                    現在『{+loginDays}問目/30問』まで回答頂いております。
                    <br />
                    ※１問につき、３質問になります。
                  </IonCardContent>
                  <IonCardContent>
                    １日最大５問×３質問（１５質問）まで、回答可能です。
                  </IonCardContent>
                  <IonCardContent>
                    ※５問の回答頂くと
                    <br />
                    「回答をはじめる」が
                    <br />
                    『本日は、ここまで』と表示されます
                  </IonCardContent>
                  <IonCardContent>
                    <a href="https://greeds3.com/home" className="color-red">
                      回答Top画面に戻る
                    </a>
                  </IonCardContent>
                  <IonCardContent>
                    『問い』の更新は『AM7:00』になります。
                  </IonCardContent>
                </>
              )}
              {loginDays === "30" && (
                <>
                  <IonCardContent>
                    30問お疲れ様でした。（30/30問×3質問）
                  </IonCardContent>
                  <IonCardContent>
                    これで無事に、診断が終了しました。
                  </IonCardContent>
                  <IonCardContent>
                    左上の
                    <IonIcon icon={home} />
                    マークをクリックして診断結果をご覧いただければと思います。
                  </IonCardContent>
                </>
              )}
            </IonCard>
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export default Logout;

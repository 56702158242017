const answerA = "確かに";
const answerB = "違う";
const answerC = "どちらでもない";
let score1: number;
let score2: number;

// スコアの計算
export const scoreCalculation = (
  question1: string,
  question2: string,
  question3: string
) => {
  if (question1 === answerA && question2 === answerA && question3 === answerA) {
    console.log("パターン1");
    score1 = 2;
    score2 = 1;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerA && question2 === answerA && question3 === answerB) {
    console.log("パターン2");
    score1 = 1;
    score2 = 3;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerA && question2 === answerA && question3 === answerC) {
    console.log("パターン3");
    score1 = 1;
    score2 = 1;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerA && question2 === answerB && question3 === answerA) {
    console.log("パターン4");
    score1 = 6;
    score2 = 0;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerA && question2 === answerB && question3 === answerB) {
    console.log("パターン5");
    score1 = 3;
    score2 = 1;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerA && question2 === answerB && question3 === answerC) {
    console.log("パターン6");
    score1 = 3;
    score2 = 0;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerA && question2 === answerC && question3 === answerA) {
    console.log("パターン7");
    score1 = 2;
    score2 = 0;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerA && question2 === answerC && question3 === answerB) {
    console.log("パターン8");
    score1 = 1;
    score2 = 1;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerA && question2 === answerC && question3 === answerC) {
    console.log("パターン9");
    score1 = 1;
    score2 = 0;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerB && question2 === answerA && question3 === answerA) {
    console.log("パターン1B");
    score1 = 1;
    score2 = 3;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerB && question2 === answerA && question3 === answerB) {
    console.log("パターン2B");
    score1 = 0;
    score2 = 6;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerB && question2 === answerA && question3 === answerC) {
    console.log("パターン3B");
    score1 = 0;
    score2 = 3;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerB && question2 === answerB && question3 === answerA) {
    console.log("パターン4B");
    score1 = 3;
    score2 = 1;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerB && question2 === answerB && question3 === answerB) {
    console.log("パターン5B");
    score1 = 1;
    score2 = 2;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerB && question2 === answerB && question3 === answerC) {
    console.log("パターン6B");
    score1 = 1;
    score2 = 1;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerB && question2 === answerC && question3 === answerA) {
    console.log("パターン7B");
    score1 = 1;
    score2 = 1;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerB && question2 === answerC && question3 === answerB) {
    console.log("パターン8B");
    score1 = 0;
    score2 = 2;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerB && question2 === answerC && question3 === answerC) {
    console.log("パターン9B");
    score1 = 0;
    score2 = 1;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerC && question2 === answerA && question3 === answerA) {
    console.log("パターン1C");
    score1 = 1;
    score2 = 1;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerC && question2 === answerA && question3 === answerB) {
    console.log("パターン2C");
    score1 = 0;
    score2 = 3;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerC && question2 === answerA && question3 === answerC) {
    console.log("パターン3C");
    score1 = 0;
    score2 = 1;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerC && question2 === answerB && question3 === answerA) {
    console.log("パターン4C");
    score1 = 3;
    score2 = 0;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerC && question2 === answerB && question3 === answerB) {
    console.log("パターン5C");
    score1 = 1;
    score2 = 1;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerC && question2 === answerB && question3 === answerC) {
    console.log("パターン6C");
    score1 = 1;
    score2 = 0;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerC && question2 === answerC && question3 === answerA) {
    console.log("パターン7C");
    score1 = 1;
    score2 = 0;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerC && question2 === answerC && question3 === answerB) {
    console.log("パターン8C");
    score1 = 0;
    score2 = 1;
    return { score1: score1, score2: score2 };
  }
  if (question1 === answerC && question2 === answerC && question3 === answerC) {
    console.log("パターン9C");
    score1 = 0;
    score2 = 0;
    return { score1: score1, score2: score2 };
  }
};

export const getUserDataAtHomePage = /* GraphQL */ `
  query userNameGetDataAtHomePage(
    $userName: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNameByIndex(
      userName: $userName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        loginDays
        userName
        id
        scoreA
        scoreB
        scoreC
        scoreD
        answerLimit
        answerValidation
        updatedAt
      }
      nextToken
    }
  }
`;

// 取得項目の制御は可能
// またキャストする型によっては取得する方法がいろいろと変わってくる。

export const listUserDatasLoginDaysOnly = /* GraphQL */ `
  query userNameLoginDaysOnly(
    $userName: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userNameByIndex(
      userName: $userName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        loginDays
      }
      nextToken
    }
  }
`;

import {
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import "./Result.css";
import { PolarRadiusAxis, Radar, RadarChart } from "recharts";
import Header from "../../components/top/Header";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { UserNameByIndexQuery } from "../../API";
import { userNameByIndex } from "../../graphql/queries";

const Result: React.FC = () => {
  // data
  const [userName, setUserName] = useState<string>("");
  const [scoreA, setScoreA] = useState<number>();
  const [scoreB, setScoreB] = useState<number>();
  const [scoreC, setScoreC] = useState<number>();
  const [scoreD, setScoreD] = useState<number>();
  const totalScore = scoreA! + scoreB! + scoreC! + scoreD!;
  let scoreArray = [
    { key: scoreA!, value: "A", id: "resultA" },
    { key: scoreB!, value: "B", id: "resultB" },
    { key: scoreC!, value: "C", id: "resultC" },
    { key: scoreD!, value: "D", id: "resultD" },
  ];
  scoreArray.sort(
    (a: { key: number; value: string }, b: { key: number; value: string }) => {
      if (a.key < b.key) return -1;
      if (a.key > b.key) return 1;
      return 0;
    }
  );
  console.log(scoreArray);
  const data = [
    {
      subject: "scoreD",
      A: scoreD,
      fullMark: 100,
    },
    {
      subject: "scoreC",
      A: scoreC,
      fullMark: 100,
    },
    {
      subject: "scoreB",
      A: scoreB,
      fullMark: 100,
    },
    {
      subject: "scoreA",
      A: scoreA,
      fullMark: 100,
    },
  ];

  // userNameを取得するタイミングでauthuserを実行
  useEffect(() => {
    authuser();
    getUserData();
    // eslint-disable-next-line
  }, [userName]);

  const authuser = async () => {
    await Auth.currentUserInfo().then((res) => {
      setUserName(res.username);
    });
  };

  const getUserData = async () => {
    const result = await API.graphql(
      graphqlOperation(userNameByIndex, {
        userName: userName,
      })
    );
    if ("data" in result && result.data) {
      const posts = result.data as UserNameByIndexQuery;
      setScoreA(posts.userNameByIndex!.items![0]?.scoreA!);
      setScoreB(posts.userNameByIndex!.items![0]?.scoreB!);
      setScoreC(posts.userNameByIndex!.items![0]?.scoreC!);
      setScoreD(posts.userNameByIndex!.items![0]?.scoreD!);
    }
  };

  // ts

  return (
    <IonPage>
      {!userName && <div>Loading...</div>}
      {userName && (
        <>
          <Header />
          <IonContent class="home">
            <IonCard class="result-card">
              <IonCardHeader>
                <IonCardTitle class="title">
                  診断結果（エネルギー量）
                </IonCardTitle>
              </IonCardHeader>

              <IonCardContent class="resultTop">
                あなたの欲求エネルギー量（GP）の
                <br />
                合計は、
                <big>{totalScore}GP</big> / 200GPです
              </IonCardContent>
              <IonCardContent class="resultA">
                <big>A.成長欲求：{scoreA}GP</big>
                /100GP
              </IonCardContent>
              <IonCardContent class="resultB">
                <big>B.安定欲求：{scoreB}GP</big>
                /100GP
              </IonCardContent>
              <IonCardContent class="resultC">
                <big>C.共感欲求：{scoreC}GP</big>
                /100GP
              </IonCardContent>
              <IonCardContent class="resultD">
                <big>D.破壊欲求：{scoreD}GP</big>
                /100GP
              </IonCardContent>
              <IonCardContent class="resultTop">
                ※エネルギー量の単位 = GP
              </IonCardContent>
              <IonCardContent>
                <a href="https://note.com/info_greed_s3/n/nfda54a0941ec">
                  GPについての解説は、こちらをクリック
                </a>
              </IonCardContent>
            </IonCard>
            <div className="radar-position">
              <div className="verticalLine"></div>
              <div className="horizontalLine"></div>
              <div className="diagonal-45Line"></div>
              <div className="diagonal-135Line"></div>
              <div className="energyA">Aの成長欲求</div>
              <div className="energyB">Bの安定欲求</div>
              <div className="energyC">Cの共感欲求</div>
              <div className="energyD">Dの破壊欲求</div>
              <div className="energyA1">{scoreA}GP</div>
              <div className="energyB1">{scoreB}GP</div>
              <div className="energyC1">{scoreC}GP</div>
              <div className="energyD1">{scoreD}GP</div>
              <div className="score50">50</div>
              <div className="score25">25</div>
              <div className="circleSample"></div>
              <div className="circleSample2"></div>
              <div className="circleSample3"></div>
              <div className="circleSample4"></div>
              <div className="circleSample5">
                <RadarChart
                  className="radar"
                  outerRadius={125}
                  width={250}
                  height={250}
                  data={data}
                >
                  <PolarRadiusAxis
                    angle={180}
                    domain={[0, 100]}
                    tick={false}
                    axisLine={false}
                  />
                  <Radar
                    className="radar-chart"
                    name="Mike"
                    dataKey="A"
                    stroke="blue"
                    fill="blue"
                    fillOpacity={0.4}
                  />
                </RadarChart>
              </div>
            </div>
            <IonCard class="result-card2">
              <IonCardHeader>
                <IonCardTitle class="cardTitle">
                  優勢欲求（100%計算）
                </IonCardTitle>
                （誤差0.0~0.1%以内）
              </IonCardHeader>
              <IonCardContent class="resultA">
                <big>
                  A.成長欲求：
                  {Math.round((+scoreA! / +totalScore) * 100 * 10) / 10}%
                </big>
                /100%
              </IonCardContent>
              <IonCardContent class="resultB">
                <big>
                  B.安定欲求：
                  {Math.round((+scoreB! / +totalScore) * 100 * 10) / 10}%
                </big>
                /100%
              </IonCardContent>
              <IonCardContent class="resultC">
                <big>
                  C.共感欲求：
                  {Math.round((+scoreC! / +totalScore) * 100 * 10) / 10}%
                </big>
                /100%
              </IonCardContent>
              <IonCardContent class="resultD">
                <big>
                  D.破壊欲求：
                  {Math.round((+scoreD! / +totalScore) * 100 * 10) / 10}%
                </big>
                /100%
              </IonCardContent>
              <IonCardContent class="desire-to-dominate">
                ※25%以上が『優勢欲求』になります
              </IonCardContent>
              <IonCardContent>
                <a href="https://note.com/info_greed_s3/n/nd7dfb292a7ae">
                  優勢欲求の解説は、こちらをクリック
                </a>
              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle class="cardTitle">
                  価値観（欲求の強い順番）
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent class="result-sort">
                <span className={scoreArray[3].id}>
                  <big className="no-1">{scoreArray[3].value}</big>
                </span>
                <big className="no-1"> → </big>
                <span className={scoreArray[2].id}>
                  <big className="no-1">{scoreArray[2].value}</big>
                </span>
                <big className="no-1"> → </big>
                <span className={scoreArray[1].id}>
                  <big className="no-1">{scoreArray[1].value}</big>
                </span>
                <big className="no-1"> → </big>
                <span className={scoreArray[0].id}>
                  <big className="no-1">{scoreArray[0].value}</big>
                </span>
              </IonCardContent>
              <IonCardContent>
                <a href="https://note.com/info_greed_s3/n/n890e18780795">
                  価値観の解説は、こちらをクリック
                </a>
              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonCardContent>
                自分の診断結果をもっと詳しく知りたい方は
              </IonCardContent>
              <IonButton
                expand="block"
                color="warning"
                href="https://lin.ee/x0RCaJA"
              >
                美容師の方はこちら
              </IonButton>
              <IonButton
                expand="block"
                color="success"
                href="https://lin.ee/oiehiLK"
              >
                一般の方はこちら
              </IonButton>
            </IonCard>
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export default Result;

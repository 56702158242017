import {
  IonButton,
  IonContent,
  IonPage,
  // useIonViewDidEnter,
} from "@ionic/react";
import React from "react";
import "./Login.css";

import {
  Plugins,
  // PushNotification,
  // PushNotificationToken,
  // PushNotificationActionPerformed,
} from "@capacitor/core";
const { Storage } = Plugins;

// バッチ削除
// const resetBadgeCount = () => {
//   PushNotifications.removeAllDeliveredNotifications();
// };

// const push = () => {
//   PushNotifications.requestPermission().then((permission) => {
//     if (permission.granted) {
//       PushNotifications.register();
//     } else {
//       // No permission for push granted
//     }
//   });

//   PushNotifications.addListener(
//     "registration",
//     (token: PushNotificationToken) => {
//       console.log("My token: " + JSON.stringify(token));
//     }
//   );

//   PushNotifications.addListener("registrationError", (error: any) => {
//     console.log("Error: " + JSON.stringify(error));
//   });

//   PushNotifications.addListener(
//     "pushNotificationReceived",
//     async (notification: PushNotification) => {
//       console.log("Push received: " + JSON.stringify(notification));
//     }
//   );

//   PushNotifications.addListener(
//     "pushNotificationActionPerformed",
//     async (notification: PushNotificationActionPerformed) => {
//       console.log(
//         "Action performed: " + JSON.stringify(notification.notification)
//       );
//     }
//   );
// };

const Login: React.FC = () => {
  // useIonViewDidEnter(() => {
  //   push();
  // });
  const clearStorage = async () => {
    await Storage.clear();
  };
  return (
    <IonPage>
      <IonContent class="background-image">
        <IonButton
          class="login-button"
          onClick={clearStorage}
          href="/login-home"
          expand="block"
        >
          ログイン
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Login;

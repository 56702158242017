import React from "react";
import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  IonCard,
  IonCardContent,
} from "@ionic/react";
import { arrowForward } from "ionicons/icons";
import "./Tutorial.css";
import { RouteComponentProps } from "react-router";
import Image from "../../assets/intro1.png";

interface OwnProps extends RouteComponentProps {}

const UserRegistration: React.FC = (history) => {
  const nextSlide = async () => {
    window.location.href = "/slide2";
  };

  return (
    <IonPage>
      <IonContent fullscreen class="home-content">
        <IonCard class="explanation-slide">
        <img src={Image} alt="イントロ画像"/>
          <IonCardContent>
            <IonButton fill="clear" onClick={nextSlide}>
              1/5 次へ
              <IonIcon slot="end" icon={arrowForward} />
            </IonButton>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default UserRegistration;
